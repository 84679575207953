import moment from 'moment';
import React from 'react';
import { useMeasure } from 'react-use';
import CtaLink from '../atoms/CtaLink';
import Image from '../base/Image';

interface BlogPostHeaderProps {
  title: string;
  date: string;
  returnUrl: string;
  featuredImage: {
    sourceUrl: string;
    altText: string;
  };
  typeLabel?: string;
}

const BlogPostHeader: React.FC<BlogPostHeaderProps> = ({
  title,
  date,
  returnUrl,
  featuredImage,
  typeLabel = 'Blog',
}) => {
  const [ref, { height }] = useMeasure();

  return (
    <div className="relative bg-tertiary mb-28">
      <section className="pt-12 lg:pt-24 u-container">
        <div className="items-center justify-between md:flex mb-14">
          <div className="flex flex-1 mb-6 md:mb-0">
            <CtaLink to={returnUrl} label="Go back" reversed />
          </div>

          <div className="flex items-center justify-center space-x-5 bg-white flex-2 shadow-glassdoor py-3.5 px-6 font-semibold rounded-lg mx-auto">
            <div>{typeLabel}</div>
            <div className="text-primary-red">
              {moment(date).format('DD MMMM')}
            </div>
          </div>
          <div className="flex-1" />
        </div>

        <h1
          className="max-w-4xl mx-auto mb-16 text-center u-h3"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </section>
      <div className="relative" style={{ height: height + 'px' }}>
        <div
          className="absolute bottom-0 left-0 right-0 z-0 bg-white"
          style={{ height: height / 2 + 'px' }}
        />
        <div className="absolute inset-0 z-10 lg:px-20">
          <div ref={ref} className="max-w-4xl mx-auto">
            <Image data={featuredImage} className="w-full h-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPostHeader;
