import React from 'react';
import WYSIWYG from '../blocks/WYSIWYG';
import FullWidthImage from '../blocks/FullWidthImage';
import SplitTextAndImage from '../blocks/SplitTextAndImage';
import FullWidthQuote from '../blocks/FullWidthQuote';

interface DynamicContentProps {
  blocks: any[];
}

const DynamicContent: React.FC<DynamicContentProps> = ({ blocks }) => {
  return (
    <>
      {blocks?.map((block, index) => {
        const mapping = {
          Wysiwyg: <WYSIWYG {...block} />,
          FullWidthImage: <FullWidthImage {...block} />,
          SplitTextAndImage: <SplitTextAndImage {...block} />,
          FullWidthQuote: <FullWidthQuote {...block} />,
        };

        const type = block.fieldGroupName.split('_').pop();

        if (type in mapping) {
          return <div key={index}>{mapping[type]}</div>;
        }

        return null;
      }) || null}
    </>
  );
};

export default DynamicContent;
