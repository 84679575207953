import { graphql, useStaticQuery } from 'gatsby';
import moment from 'moment';
import React from 'react';
import { ResourceType } from '../../types/resource-type';
import ArrowRight from './ArrowRight';

interface ResourceLeadProps {
  dateGmt: string;
  nodeType: ResourceType;
  eventDate?: string;
}

const ResourceLead: React.FC<ResourceLeadProps> = ({
  dateGmt,
  nodeType,
  eventDate,
}) => {
  const {
    wp: {
      options: {
        globalOptions: { resourceLabels },
      },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        options {
          globalOptions {
            resourceLabels {
              blogPost
              event
              guide
              newsItem
            }
          }
        }
      }
    }
  `);

  const labelMapping = {
    NewsItem: resourceLabels.newsItem,
    Post: resourceLabels.blogPost,
    Event: resourceLabels.event,
    Guide: resourceLabels.guide,
  };

  const formattedDate =
    nodeType === 'Event' ? eventDate ? moment(eventDate).format('DD MMMM')
        : null
      : moment.utc(dateGmt).format('DD MMMM');

  return (
    <div className="flex items-center space-x-3">
      <span className="text-sm md:text-base text-primary-red">
        {labelMapping[nodeType]}
      </span>

      {formattedDate && (
        <>
          <ArrowRight />{' '}
          <span className="text-sm text-black md:text-base">
            {formattedDate}
          </span>
        </>
      )}
    </div>
  );
};

export default ResourceLead;
