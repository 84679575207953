import React from 'react';

const LinkedInIcon: React.FC = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8191 0.916992H0.65913C0.294549 0.916992 0 1.21154 0 1.57612V16.7361C0 17.1007 0.294549 17.3952 0.65913 17.3952H15.8191C16.1837 17.3952 16.4782 17.1007 16.4782 16.7361V1.57612C16.4782 1.21154 16.1837 0.916992 15.8191 0.916992ZM4.88786 14.9585H2.4429V7.09427H4.88786V14.9585ZM3.66641 6.01907C3.38613 6.01907 3.11214 5.93596 2.87909 5.78024C2.64605 5.62452 2.46441 5.4032 2.35715 5.14425C2.24989 4.88531 2.22183 4.60037 2.27651 4.32547C2.33119 4.05058 2.46616 3.79807 2.66435 3.59988C2.86254 3.40169 3.11505 3.26672 3.38994 3.21204C3.66484 3.15736 3.94977 3.18542 4.20872 3.29268C4.46767 3.39994 4.68899 3.58158 4.84471 3.81463C5.00043 4.04767 5.08354 4.32166 5.08354 4.60194C5.08148 5.38466 4.44707 6.01907 3.66641 6.01907ZM14.0415 14.9585H11.5986V11.1335C11.5986 10.221 11.5821 9.04901 10.3277 9.04901C9.05686 9.04901 8.86118 10.0418 8.86118 11.0676V14.9585H6.42034V7.09427H8.76437V8.16948H8.79732C9.12277 7.55155 9.9199 6.8986 11.1105 6.8986C13.5863 6.8986 14.0415 8.52788 14.0415 10.6453V14.9585Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkedInIcon;
