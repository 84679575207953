import React from 'react';

interface SplitTextAndImageProps {
  content: string;
  image: {
    sourceUrl: string;
    altText: string;
  };
  imageDescription: string;
}

const SplitTextAndImage: React.FC<SplitTextAndImageProps> = ({
  content,
  image,
  imageDescription,
}) => (
  <section className="mx-auto space-y-12 lg:flex lg:space-x-20 lg:space-y-0 max-w-8xl">
    <div className="lg:w-1/2">
      <img
        src={image?.sourceUrl}
        alt={image?.altText}
        className="w-full h-auto"
      />
    </div>
    <div className="lg:w-1/2">
      <div
        className="max-w-full prose prose-xl w-120 mb-7"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <hr className="mb-6 border-b border-ghost-grey" />
      <div className="text-spirable-black opacity-60">{imageDescription}</div>
    </div>
  </section>
);

export default SplitTextAndImage;
