import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import BlogPostHeader from '../components/organisms/BlogPostHeader';
import DynamicContent from '../components/organisms/DynamicContent';
import ResourceRelatedArticlesSection from '../components/organisms/ResourceRelatedArticlesSection';
import ShareResourceSection from '../components/organisms/ShareResourceSection';

const BlogPostPage = ({ data: { page } }) => {
  return (
    <Layout>
      <Seo post={page} />

      <BlogPostHeader
        title={page.title}
        date={page.date}
        returnUrl="/blog"
        featuredImage={page?.featuredImage?.node}
      />
      <DynamicContent blocks={page.blogPost.content} />
      <ShareResourceSection backUrl="/blog/" title={page.title} />
      {page.blogPost.relatedArticles && (
        <ResourceRelatedArticlesSection
          title="Related Articles"
          viewAllUrl="/blog"
          resources={page.blogPost.relatedArticles}
        />
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    page: wpPost(id: { eq: $id }) {
      ...PostSEO
      date
      title
      nodeType
      slug
      featuredImage {
        node {
          ...Image
        }
      }
      blogPost {
        content {
          ... on WpPost_Blogpost_Content_Wysiwyg {
            content
            fieldGroupName
          }
          ... on WpPost_Blogpost_Content_FullWidthImage {
            description
            fieldGroupName
            image {
              ...Image
            }
          }
          ... on WpPost_Blogpost_Content_SplitTextAndImage {
            content
            fieldGroupName
            imageDescription
            image {
              ...Image
            }
          }
          ... on WpPost_Blogpost_Content_FullWidthQuote {
            fieldGroupName
            quote
            author {
              name
              title
            }
          }
        }
        # relatedArticles {
        #   ... on WpPost {
        #     title
        #     slug
        #     nodeType
        #     dateGmt
        #     featuredImage {
        #       node {
        #         ...Image
        #       }
        #     }
        #   }
        #   ... on WpGuide {
        #     title
        #     slug
        #     nodeType
        #     date
        #     featuredImage {
        #       node {
        #         ...Image
        #       }
        #     }
        #   }
        #   ... on WpNewsItem {
        #     title
        #     slug
        #     nodeType
        #     date
        #     featuredImage {
        #       node {
        #         ...Image
        #       }
        #     }
        #   }
        #   ... on WpEvent {
        #     title
        #     slug
        #     nodeType
        #     date
        #     featuredImage {
        #       node {
        #         ...Image
        #       }
        #     }
        #     event {
        #       eventDate
        #     }
        #   }
        # }
      }
    }
  }
`;

export default BlogPostPage;
