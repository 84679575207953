import React from 'react';

interface FullWidthImageProps {
  image: {
    sourceUrl: string;
    altText: string;
  };
  description: string;
}

const FullWidthImage: React.FC<FullWidthImageProps> = ({
  image,
  description,
}) => (
  <div className="u-container">
    <img
      src={image?.sourceUrl}
      alt={image?.altText}
      className="w-full h-auto"
    />
    {description && (
      <div className="px-6 border-b py-7 border-ghost-grey text-15px text-spirable-black">
        {description}
      </div>
    )}
  </div>
);

export default FullWidthImage;
