import React from 'react';

const FacebookIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    fill="none"
    viewBox="0 0 17 17"
  >
    <path
      fill="currentColor"
      d="M2.967.417A2.96 2.96 0 000 3.384v10.314a2.96 2.96 0 002.967 2.966h5.59v-6.351h-1.68V8.026h1.68V6.072c0-1.535.992-2.944 3.278-2.944.926 0 1.61.089 1.61.089l-.054 2.135s-.698-.006-1.46-.006c-.824 0-.956.38-.956 1.01v1.67h2.482l-.108 2.287h-2.374v6.351h2.306a2.96 2.96 0 002.966-2.966V3.384A2.96 2.96 0 0013.281.417H2.967z"
    ></path>
  </svg>
);

export default FacebookIcon;
