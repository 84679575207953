import React from 'react';

const TwitterIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    fill="none"
    viewBox="0 0 20 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.278 2.01a7.91 7.91 0 01-2.272.623 3.967 3.967 0 001.74-2.188 7.922 7.922 0 01-2.512.96 3.955 3.955 0 00-6.74 3.607A11.229 11.229 0 011.342.88a3.953 3.953 0 001.224 5.28 3.94 3.94 0 01-1.791-.495v.05a3.958 3.958 0 003.172 3.878 3.96 3.96 0 01-1.786.068 3.96 3.96 0 003.694 2.747A7.936 7.936 0 01.943 14.1 8.05 8.05 0 010 14.046a11.195 11.195 0 006.063 1.777c7.275 0 11.253-6.027 11.253-11.254 0-.171-.004-.342-.012-.511a8.04 8.04 0 001.974-2.048z"
    ></path>
  </svg>
);

export default TwitterIcon;
