import React from 'react';
import CtaLink from '../atoms/CtaLink';
import FacebookIcon from '../atoms/FacebookIcon';
import LinkedInIcon from '../atoms/LinkedInIcon';
import TwitterIcon from '../atoms/TwitterIcon';

interface ShareResourceSectionProps {
  backUrl: string;
  title: string;
}

const ShareResourceSection: React.FC<ShareResourceSectionProps> = ({
  backUrl,
  title,
}) => {
  const shareTwitter = () => {
    window.open(
      `https://twitter.com/share?text=${encodeURIComponent(title)}`,
      '_blank',
      'width=500,height=400',
    );
  };
  const shareLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(
        window.location.href,
      )}&title=${encodeURIComponent(title)}`,
      '_blank',
      'width=500,height=400',
    );
  };
  const shareFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer.php`,
      '_blank',
      'width=500,height=400',
    );
  };

  return (
    <div
      className="flex items-center justify-between max-w-4xl px-5 mx-auto mb-18 lg:mb-36"
      style={{ maxWidth: 885 }}
    >
      <CtaLink to={backUrl} label="Go back" reversed />

      <div className="flex items-center space-x-2.5">
        <button onClick={shareFacebook} className="u-social-icon">
          <FacebookIcon />
        </button>
        <button onClick={shareTwitter} className="u-social-icon">
          <TwitterIcon />
        </button>
        <button onClick={shareLinkedIn} className="u-social-icon">
          <LinkedInIcon />
        </button>
      </div>
    </div>
  );
};

export default ShareResourceSection;
