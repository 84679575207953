import { Link } from 'gatsby';
import { Stringifier } from 'postcss';
import React from 'react';
import { ResourceType } from '../../types/resource-type';
import CtaLink from '../atoms/CtaLink';
import ResourceTile from '../atoms/ResourceTile';

interface ResourceRelatedArticlesSectionProps {
  title: string;
  viewAllUrl: string;
  resources: {
    title: string;
    nodeType: ResourceType;
    dateGmt: string;
    slug: string;
    featuredImage?: {
      node: {
        sourceUrl: string;
      };
    };
    event?: {
      eventDate: string;
    };
  }[];
}

const ResourceRelatedArticlesSection: React.FC<ResourceRelatedArticlesSectionProps> = ({
  title,
  viewAllUrl,
  resources,
}) => (
  <section className="pt-20 pb-20 border-t lg:pt-38 lg:pb-40 border-ghost-grey">
    <div className="u-container">
      <div className="items-center justify-between mb-16 space-y-3 md:flex md:space-y-0">
        <h2 className="u-h3">{title}</h2>
        <CtaLink to={viewAllUrl} label="View all" />
      </div>

      <div className="justify-between space-y-6 md:flex md:space-x-8 md:space-y-0">
        {resources.slice(0, 2).map((resource, index) => (
          <div className="md:w-1/2" key={index}>
            <ResourceTile {...resource} />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default ResourceRelatedArticlesSection;
