import React from 'react';

interface FullWidthQuoteProps {
  quote: string;
  author: {
    name: string;
    title: string;
  };
}

const FullWidthQuote: React.FC<FullWidthQuoteProps> = ({ quote, author }) => (
  <section className="flex flex-col items-center max-w-5xl pt-24 mx-auto">
    <img src="/images/quotes.png" alt="" className="w-12 h-auto mb-8" />
    <q className="mx-auto mb-8 text-4xl font-semibold text-center quotes-none text-primary-red">
      {quote}
    </q>
    <div className="text-center">
      <div className="font-semibold">{author.name}</div>
      <div className="text-body-copy">{author.title}</div>
    </div>
  </section>
);

export default FullWidthQuote;
