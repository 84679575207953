import { Link } from 'gatsby';
import React from 'react';
import { getResourceUrl } from '../../helpers/resources';
import { IImage } from '../../interfaces/image';
import { ResourceType } from '../../types/resource-type';
import CtaLink from './CtaLink';
import ResourceLead from './ResourceLead';
import Image from '../base/Image';

interface ResourceTileProps {
  title: string;
  nodeType: ResourceType;
  dateGmt: string;
  slug: string;
  featuredImage?: {
    node: {
      sourceUrl: string;
    };
  };
  event?: {
    eventDate: string;
  };
  aspectHeight?: string;
  aspectWidth?: string;
  noBorder?: boolean;
  customerStory?: { logo: IImage };
  large?: boolean;
}

const ResourceTile: React.FC<ResourceTileProps> = ({
  title,
  nodeType,
  dateGmt,
  slug,
  featuredImage,
  event,
  aspectHeight = 'aspect-h-11',
  aspectWidth = 'aspect-w-16',
  noBorder,
  customerStory,
  large,
}) => (
  <Link
    to={getResourceUrl({ slug, nodeType })}
    className={`block h-full hover:opacity-75 transition-opacity duration-200 ${
      !noBorder && 'shadow-xl'
    } rounded-sm overflow-hidden`}
  >
    {featuredImage && (
      <div className="relative">
        <div className={`${aspectHeight} ${aspectWidth} bg-ghost-grey`}>
          <div className="absolute inset-0 w-full h-full">

            <Image
              data={featuredImage?.node}
              className="object-cover w-full h-full"
              objectFit="cover"
            />
          </div>
        </div>
        {nodeType === 'CustomerStory' && (
          <div className="absolute z-10 flex items-center justify-center w-auto min-h-0 px-8 py-3 bg-white rounded left-7 bottom-7 h-14 min-w-36">
            <img
              src={customerStory.logo.sourceUrl}
              alt={customerStory.logo.altText}
              className="w-auto h-7"
            />
          </div>
        )}
      </div>
    )}
    <div
      className={`${nodeType !== 'CustomerStory' && 'px-6'} ${
        large ? 'p-5 lg:p-8' : 'pt-6 pb-6'
      }`}
    >
      {nodeType !== 'CustomerStory' && (
        <div className="mb-2.5 font-semibold text-primary-red">
          <ResourceLead
            dateGmt={dateGmt}
            nodeType={nodeType}
            eventDate={event?.eventDate}
          />
        </div>
      )}
      <h3
        className={`${
          large ? 'text-xl lg:text-3xl' : 'text-base md:text-xl'
        } font-semibold`}
      >
        {title}
      </h3>
      {nodeType === 'CustomerStory' && (
        <div className="mt-4">
          <CtaLink to={`/case-studies/${slug}`} label="Read Story" divOnly />
        </div>
      )}
    </div>
  </Link>
);

export default ResourceTile;
