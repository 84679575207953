import React from 'react';

interface WYSIWYGProps {
  content: string;
}

const WYSIWYG: React.FC<WYSIWYGProps> = ({ content }) => (
  <section
    className="pb-24 prose prose-lg lg:prose-xl mt-28 u-container"
    dangerouslySetInnerHTML={{ __html: content }}
  ></section>
);

export default WYSIWYG;
